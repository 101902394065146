import React,{useState,useEffect} from 'react'

import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { ProgressBar } from "react-bootstrap"
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import JsonData from './data.json'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

const GalleryFiles=({ history })=> {
  const divStyle = {
    overflowY: 'scroll',
    border:'1px solid silver',
    width:'400px',
    float: 'left',
    height:'300px',
    position:'absulute'
  };
  
    const [content,setContent]=useState([])
    const [event_name,setEventName]=useState("")

   
    const [progress, setProgress] = useState()

      const getGalleryFiles = () => {
      
        let rowDataProgramId = localStorage.getItem('selectedRowDataGalleryWithEvents')
           let rowDataGalleryId = localStorage.getItem('selectedRowDataGalleryYear')
        
        var galleryDetails= JSON.parse(rowDataGalleryId)
        var programDetails= JSON.parse(rowDataProgramId)

        setEventName(programDetails.program_name)
        var gallery_id=galleryDetails.gallery_id
        var program_id=programDetails.program_id
        axios
        .post(
            `${API}/getPhotosByEvent`,
          JSON.stringify({
          
            gallery_id:gallery_id,
            program_id:program_id
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                  setContent(response.data.photos.files);     
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };
      const deletePhoto=(photoName)=>
      {
        console.log(photoName)
        let rowDataProgramId = localStorage.getItem('selectedRowDataGalleryWithEvents')
        let rowDataGalleryId = localStorage.getItem('selectedRowDataGalleryYear')
     
     var galleryDetails= JSON.parse(rowDataGalleryId)
     var programDetails= JSON.parse(rowDataProgramId)

     setEventName(programDetails.program_name)
     var gallery_id=galleryDetails.gallery_id
     var program_id=programDetails.program_id
     axios({
      method: 'DELETE',
      url:  `${API}/deleteGalleryPhotos`,
      data: {
        galleryId: gallery_id,
        programId:program_id,
        photoName:photoName
      
      }
     
     }).then(
      res=>{
          console.log(res)
          if(res.status===200)
          {
              if(res.data.status==true)
              {
                toast('Deleted Successfully')                                
               
              }
          
          }
          else
          {
           
            console.log("error")
          }
      }
    )
      
      }
      
      useEffect(() => {
        getGalleryFiles();
        
      }, []);
    const GalleryFilesScreen=()=>{
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            
         
          filesdata:[]
        
          };        
          const validationSchema=Yup.object({
            filesdata:Yup.array().min(1,"select at least 1 file")
          })
        const onSubmit = async (values, submitProps) => {
          submitProps.setSubmitting(true); 
           console.log(values)
           var formData = new FormData();
           var filesdata = JSON.stringify( values.filesdata)
         
           console.log(filesdata)
           let rowDataGalleryId =  localStorage.getItem ('selectedRowDataGalleryYear')
           let rowDataProgramId =  localStorage.getItem('selectedRowDataGalleryWithEvents')
           var programDetails= JSON.parse(rowDataProgramId)
           var galleryDetails= JSON.parse(rowDataGalleryId)

           var gallery_id=galleryDetails.gallery_id
           var program_id=programDetails.program_id
        
           values.filesdata.forEach(file=>{
            formData.append("filesdata", file);
          });

                                 
            formData.append("gallery_id",JSON.stringify(gallery_id))
            formData.append("program_id",JSON.stringify(program_id))
            console.log(formData)
           axios({
            method: "post",
            url: `${API}/addgalleryphotos`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },

            onUploadProgress: data => {
              //Set the progress value to show the progress bar
              setProgress(Math.round((100 * data.loaded) / data.total))
            },
          })                  
          .then((response) => {
              
            
            if(response.status===200)
              {
                submitProps.setSubmitting(false); 
                  if(response.data.status=="Success")
                  {      
                    getGalleryFiles()
                    toast('Images uploaded successfully')
                    // getIQACContenent()
                    setTimeout(() => {
                      setProgress()
                    }, 5000);
                    
                  }
                  else
                  {
                      submitProps.setSubmitting(false);
                   
                     alert(response.data.message)
                     
                  }                 
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
          
            console.log(error.message);
          });
        
          };
        return(
            <div className="container-fluid" style={{marginTop:"50px"}}>
              <button type="button" onClick={() => history.push('/admin/galleryeventname')} class="btn btn-warning">Go Back</button>
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 {event_name}
</div>
                <div className="row">
                <div className="col-md-6">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
               
                   <div className="form-group">
                    <label className="text-black">Upload File</label><label style={{color:"red"}}>*</label>
                    <input
                    className="form-control"
              type="file"
              name="filesdata"
              accept="image/*"
              multiple 

              onChange={(event) => {
                const files = event.target.files;
                let myFiles =Array.from(files);
                formik.setFieldValue("filesdata", myFiles);
              }}
              // onChange={(event) => {
              //   formik.setFieldValue("filesdata", event.currentTarget.files);
              //  // formik. setFieldValue("filesdata", event.currentTarget.files[0]);
              
              // }}
            />
              <ErrorMessage name="filesdata">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>   

              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>

                  {progress && <ProgressBar now={progress} label={`${progress}%`} />}
            </Form>
          );
        }}
      </Formik>
      </div>
      </div>


      <SimpleReactLightbox>
      <SRLWrapper>   
<div className="row" style={{marginTop:"10px",marginBottom:"50px"}}>   
{
   content.map(
    (info) =>
    {
      var count = Object.keys(info).length;
      console.log(info);
    
      if(count>0)
      {  
        return(    
          
                  <div className="col-md-3">
                   <a href="#" onClick={()=>deletePhoto(info.file_url_name)}>Delete Photo</a> 
      
                  <div class="gallery-container" style={{marginTop:"30px"}}>
                
                    {/* <img src={"http://localhost:8000/photogallery/"+info.file_url_name} alt={event_name} />*/}
                   
                     <img src={APIMEDIA+"/photogallery/"+info.file_url_name} alt={event_name} />
                   
                    </div>
                  </div>

                
                 
        )
      }
    }
   )

    }

                  </div>
                  </SRLWrapper> 
                   </SimpleReactLightbox>
                  
                 

                         
    
                
              
       
     
     


     
     
            </div>
            )
    }

    return(
        <BaseAdmin>
        {GalleryFilesScreen()}
        </BaseAdmin>
    )

}

export default GalleryFiles