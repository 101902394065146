import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../backend'
import { APIMEDIA } from '../backend'
const IQAC=()=> {
    const [section_name, setSectionName] = useState("");
  
    const [allIQACsections, setAllIQACSections] = useState([]);
    const preload = () => {
    
      axios
      .get(
          `${API}/getIQACSections`,            
      )
      .then((response) => {
        console.log(response);
        if(response.status===200)
          {
              if(response.data.status==true)
              {      
                setAllIQACSections(response.data.iqac);
              }
                
          }
          else
          {
              alert("error")
            
          }
      })
      .catch((error) => {
        console.log(error.message);
      });

      };


const deleteCourse=courseId=>{
  axios({
    method: 'DELETE',
    url:  `${API}/deleteCourses`,
    data: {
      courseId: courseId.Id,
    
    }
   
   })
  
   .then(
        res=>{
            console.log(res)
            if(res.status===200)
            {
                if(res.data.status==true)
                {
                    return alert('deleted successfully')
                 
                }
                preload()           
            }
            else
            {
              console.log("error")
            }
        }
      )
  }

      
      useEffect(() => {
        preload();
      }, []);
    const IQACScreen=()=>{
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            
            section_name: section_name,
        
          };
          const validationSchema = Yup.object({
                  
            section_name: Yup.string().required("Required"),
         
          });
        const onSubmit = (values, submitProps) => {
           console.log(values)
           submitProps.setSubmitting(true);
           axios
            .post(
                `${API}/addiqacsection`,
              JSON.stringify({
                values
               
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log(response);
              if(response.status===200)
                {
                    if(response.data.status==true)
                    {      
                      toast('Section Name Added Successfully')
                      submitProps.setSubmitting(false);
                       preload()
                    }
                    else
                    {
                        submitProps.setSubmitting(false);
                    
                       alert("response.data.message")
                    
                    }                 
                }
                else
                {
                    alert("error")
                  submitProps.setSubmitting(false);
                }
            })
            .catch((error) => {
              console.log(error.message);
            });
        
          };
        return(
            <div className="container" style={{marginTop:"50px"}}>
                <div className="row">
                <div className="col-md-6">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
            
               
                  <div className="form-group">
                    <label className="text-black">Section Name</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="section_name"
                      name="section_name"
                      
                    />
                      <ErrorMessage name="section_name">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>            
                          
             
              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>
            </Form>
          );
        }}
      </Formik>
      </div>
      <div className="col-md-6">
      <div style={{ maxWidth: '100%' }}>
      <MaterialTable  
        icons={tableIcons}  
          columns={[
           
            { title: "ID", field: "_id",editable:'never',disableClick:'true',hidden: true },
            { title: "Section", field: "section_name" },
           
            
          ]}
          data={allIQACsections}     
          title="Section"
          actions={[
            {
              icon:() => <Link to='/admin/IQACcontent'>Add</Link>,
              tooltip: 'Upload Files',
              onClick: (event, rowData) =>  localStorage.setItem('selectedRowData', JSON.stringify( rowData))
            }
          ]}  
          editable={{
            onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
              
                const dataDelete = [...allIQACsections];
                const index = oldData.tableData.id;
                const Id = oldData._id; 
                axios({
                  method: 'DELETE',
                  url:  `${API}/deleteiqacsection`,
                  data: {
                    section_id: Id,
                  
                  }
                 
                 }).then(
                  res=>{
                      console.log(res)
                      if(res.status===200)
                      {
                          if(res.data.status==true)
                          {
                            toast('Section Name Deleted Successfully')
                              dataDelete.splice(index, 1);
                              setAllIQACSections([...dataDelete]);
                    
                    resolve()
                           
                          }
                      
                      }
                      else
                      {
                        reject("Wrong")
                        console.log("error")
                      }
                  }
                )
              
              }, 1000)
            }),
          }}
        
        
          
        />
      </div>
      </div>
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {IQACScreen()}
        </BaseAdmin>
    )

}

export default IQAC