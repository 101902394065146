import React,{useState,useEffect} from 'react'

import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { ProgressBar } from "react-bootstrap"
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import JsonData from './data.json'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const StaffDetails=({ history })=> {
  const divStyle = {
    overflowY: 'scroll',
    border:'1px solid silver',
    width:'400px',
    float: 'left',
    height:'300px',
    position:'absulute'
  };
  
    const [contentTeaching,setContentTeaching]=useState([])
    const [contentNonTeaching,setContentNonTeaching]=useState([])
    const [staff_type,setStaffType]=useState("")

    const [staff_name,setStaffName]=useState("")
    const [designation,setDesignation]=useState("")
   
    const [qualification,setQualification]=useState("")
    const [joing_date,setJoiningDate]=useState("")
    const [pay_scale,setPayScale]=useState("")

   
    const [progress, setProgress] = useState()

    const getTeachingStaffData = () => {    
    
      axios
      .get(
          `${API}/getteachingstaffdetails`,        
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if(response.status===200)
          {
              if(response.data.status==true)
              {      
               
                console.log(response.data.staffdetails)
              //   var iqacDetails= response.data.iqac
              setContentTeaching(response.data.staffdetails);
              //   setSectionName(response.data.iqac[0].section_name)
              }
                
          }
          else
          {
              alert("error")
            
          }
      })
      .catch((error) => {
        console.log(error.message);
      });

      }
      const getNonTeachingStaffData = () => {    
    
        axios
        .get(
            `${API}/getnonteachingstaffdetails`,        
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.staffdetails)
                //   var iqacDetails= response.data.iqac
                setContentNonTeaching(response.data.staffdetails);
                //   setSectionName(response.data.iqac[0].section_name)
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };
        
        const deleteStaff=(_id,photo_file_name,biodata_file_name)=>{
        
          var result = window.confirm("Are you sure to delete this document?")
          if(result)
          {
         
           var photo_file_name=photo_file_name
           var biodata_file_name=biodata_file_name
           var id=_id
           axios({
             method: 'DELETE',
             url:  `${API}/deletestaffdetails`,
             data: {
              id: id,   
              photo_file_name:photo_file_name,         
              biodata_file_name:biodata_file_name            
             }           
            }).then(
             res=>{
                 console.log(res)
                 if(res.status===200)
                 {
                     if(res.data.status==true)
                     {
                       toast('Document Deleted Successfully')
                       getTeachingStaffData()    
                       getNonTeachingStaffData()                 
                      
                     }
                 
                 }
                 else
                 {
                  
                   console.log("error")
                 }
             }
           )
           }
 
         }

         const updateStaff=(_id)=>{
        
          localStorage.setItem('staff_id', JSON.stringify( _id))
          history.push("/admin/updatestaffdetails");
 
         }

      useEffect(() => {
        getTeachingStaffData();
        getNonTeachingStaffData()
      }, []);
    const StaffDetailsScreen=()=>{     
        
        const initialValues = {
          staff_type:staff_type,
          staff_name:staff_name,
          designation:designation,
          qualification:qualification,
          joing_date:joing_date,
          pay_scale:pay_scale,
          biodata:'',
          photo:''
        
          };
        
          const validationSchema=Yup.object({
            staff_type: Yup.string().required("Required"),
            staff_name: Yup.string().required("Required"),
            designation: Yup.string().required("Required"),
            qualification: Yup.string().required("Required"),
            joing_date: Yup.string().required("Required"),
            pay_scale: Yup.string().required("Required"),
            
          })
          const onSubmit = (values, submitProps) => {
            console.log(values)
            submitProps.setSubmitting(true);
            var formData = new FormData();
            var biodata = values.biodata 
            var photo = values.photo           
         //    var course_name=values.course_name
         //    var university=values.university
         //    var entry_qualification=values.entry_qualification
         //    var duration=values.duration
         //    var medium=values.medium
         //    var intake_capacity=values.intake_capacity
            
            formData.append("biodata", biodata);  
            formData.append("photo", photo);  
          
            formData.append("values",JSON.stringify(values))
          
            axios({
             method: "post",
             url: `${API}/addstaff`,
             data: formData,
             headers: { "Content-Type": "multipart/form-data" },
             onUploadProgress: data => {
              //Set the progress value to show the progress bar
              setProgress(Math.round((100 * data.loaded) / data.total))
            },
           })                  
           .then((response) => {
               
             
             if(response.status===200)
               {
                   if(response.data.status==true)
                   {      
                     toast('Saved Successfully')   
                     setTimeout(() => {
                      setProgress()
                    }, 5000);
                     getTeachingStaffData()
                     getNonTeachingStaffData()
                     submitProps.setSubmitting(false);
                   }
                   else
                   {
                       submitProps.setSubmitting(false);
                    
                      alert(response.data.message)
                      submitProps.setSubmitting(false);
                   }                 
               }
               else
               {
                   alert("error")
                 
               }
           })
           .catch((error) => {
           
             console.log(error.message);
           });
         
           };
        return(
            <div className="container-fluid" style={{marginTop:"50px"}}>
              {/* <button type="button" onClick={() => history.push('/admin/galleryeventname')} class="btn btn-warning">Go Back</button> */}
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Add Staff
</div>
                <div className="row">
                <div className="col-md-12">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
                <div className="form-group">
                    <label className="text-black">Staff Type</label><label style={{color:"red"}}>*</label>
                    <Field name="staff_type"  id="staff_type">
                                    {({ field }) => (
                                        <select {...field} className="form-control" >
                                            <option value="">--SELECT--</option>
                                            <option value="Teaching">Teaching</option>
                                            <option value="Non-Teaching">Non-Teaching</option>
                                            
                                        </select>
                                    )}
                                    </Field>
                      <ErrorMessage name="staff_type">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                    </div>   
                    <div className="form-group">
                    <label className="text-black">Full Name</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      
                      id="staff_name"
                      name="staff_name"
                      
                    />
                      <ErrorMessage name="staff_name">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>  
                  <div className="form-group">
                    <label className="text-black">Designation</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      
                      id="designation"
                      name="designation"
                      
                    />
                      <ErrorMessage name="qualification">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div> 
                  <div className="form-group">
                    <label className="text-black">Qualification</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      
                      id="qualification"
                      name="qualification"
                      
                    />
                      <ErrorMessage name="qualification">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div> 
                  <div className="form-group">
                    <label className="text-black">Joining Date</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      
                      id="joing_date"
                      name="joing_date"
                      
                    />
                      <ErrorMessage name="joing_date">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>
                  <div className="form-group">
                    <label className="text-black">Scale Of Pay</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      
                      id="pay_scale"
                      name="pay_scale"
                      
                    />
                      <ErrorMessage name="pay_scale">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>
                
                   <div className="form-group">
                    <label className="text-black">Upload Biodata File</label>
                    <input
                    className="form-control"
              type="file"
              name="biodata"
              accept="application/pdf"
               
              onChange={(event) =>{
                formik.setFieldValue("biodata", event.target.files[0]);
              }}
            
            />
            
                  </div>   
                  <div className="form-group">
                    <label className="text-black">Upload Photo</label>
                    <input
                    className="form-control"
              type="file"
              name="photo"
              accept="image/*"
              onChange={(event) =>{
                formik.setFieldValue("photo", event.target.files[0]);
              }} 
              
             
            
            />            
                  </div>   

              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>

                  {progress && <ProgressBar now={progress} label={`${progress}%`} />}
            </Form>
          );
        }}
      </Formik>
      </div>
    
      </div>
      <div className="row">
      <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Teaching Staff
</div>
      <table class="table table-bordered">
              <thead>
              <th>-</th>
              <th>Photo</th>
                  <th>Name</th>
                  <th>Designation </th>
                  <th>Qualification </th>
                  <th>Joining Date </th>
                  <th>Scale Of Pay </th>
                  <th>Biodata</th>
                 
              </thead>
                <tbody>
            
        {
            contentTeaching.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(count);
                if(count>0)
                {
                   
                  
                return(
              
                      <tr>
                        <td><a href="#" onClick={() =>deleteStaff(info._id,info.photo_file_name,info.biodata_file_name)}>Delete</a> |
                        <a href="#" onClick={() =>updateStaff(info._id)}> Update</a>
                        </td>
                          <td style={{color:'red'}}>  <img src={APIMEDIA+'/photogallery/'+info.photo_file_name} alt="Avatar" class="avatar"/> </td>
                          <td>{info.staff_name}</td>
                          <td>{info.designation}</td>
                          <td>{info.qualification}</td>
                          <td>{info.joing_date}</td>
                          <td>{info.pay_scale}</td>
                          <td style={{color:'red'}}> <a href={APIMEDIA+'/photogallery/'+info.biodata_file_name} target="_blank">  <i class="fa fa-file" aria-hidden="true"></i> </a> </td>
                          
                      </tr>
                     
                  )
                
               
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
      </div>

      <div className="row">
      <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Non Teaching Staff
</div>
      <table class="table table-bordered">
              <thead>
              <th>-</th>
              <th>Photo</th>
                  <th>Name</th>
                  <th>Designation </th>
                  <th>Qualification </th>
                  <th>Joining Date </th>
                  <th>Scale Of Pay </th>
                  <th>Biodata</th>
                 
              </thead>
                <tbody>
            
        {
            contentNonTeaching.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(count);
                if(count>0)
                {
                   
                  
                return(
              
                      <tr>
                        <td><a href="#" onClick={() =>deleteStaff(info._id,info.photo_file_name,info.biodata_file_name)}>Delete |</a>
                        <a href="#" onClick={() =>updateStaff(info._id)}> Update</a>
                        </td>
                          <td style={{color:'red'}}>  <img src={APIMEDIA+'/photogallery/'+info.photo_file_name} alt="Avatar" class="avatar"/> </td>
                          <td>{info.staff_name}</td>
                          <td>{info.designation}</td>
                          <td>{info.qualification}</td>
                          <td>{info.joing_date}</td>
                          <td>{info.pay_scale}</td>
                          <td style={{color:'red'}}> <a href={APIMEDIA+'/photogallery/'+info.biodata_file_name} target="_blank">  <i class="fa fa-file" aria-hidden="true"></i> </a> </td>
                          
                      </tr>
                     
                  )
                
               
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {StaffDetailsScreen()}
        </BaseAdmin>
    )

}

export default StaffDetails