import React from 'react'
import MenuAdmin from './MenuAdmin'

 const BaseAdmin= ({
     
     title="",
     description="",
  
    className="text-black p-4",
     children
 }) =>  
     (
         
        <body>
           
       
           { <MenuAdmin/>}
           <div className="container">
   
      {children}            
             
              </div>
            
              <footer className="footer" style={{marginTop:"50px"}}> 
                  
                  <div className="container">
                     <div className="row"></div>
                  </div>
              </footer>
               {/* <footer className="footer">
                  
                   <div className="container">
                       <span className="text-muted">
                           powered by <span className="text-white">Dextra Lab Technologies</span>
                       </span>
                   </div>
               </footer> */}
        </body>
    )
     
    // Base.oncontextmenu = function (e) {
    //     e.preventDefault();
    // };

  
export default BaseAdmin