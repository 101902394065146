import React, { useState, useEffect,useLayoutEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Base from "../core/Base.js";

import * as Yup from "yup";
import axios from "axios";
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import {Link,Redirect,useHistory} from "react-router-dom"


const Signin = () => {
 
  let history=useHistory();

    
    const initialValues = {
        email: "",
        password: "",
      };
      const onSubmit = (values, submitProps) => {
          console.log(JSON.stringify( values));
        submitProps.setSubmitting(true);

        axios
        .post(
            `${API}/signin`,
          JSON.stringify({
            values
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then(res=>{
            console.log(res)
            if(res.status===200)
            {
              if(res.data.status==true)
              {
            
              // document.cookie=`token=${res.data.token}`
              // document.cookie=`refresh_token=${res.data.refreshToken}`
              localStorage.setItem('Email', res.data.user.email);
              history.replace('/admin/dashboard')
              }
              else
              {
                alert(res.data.error)
                submitProps.setSubmitting(false);
              }
             
              
            }
            else
            {
              alert("Hi")
              submitProps.setSubmitting(false);
            }
        });
      
       // history.replace('/admin/dashboard')
        
       
      };
      
      const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required("Required"),
        password: Yup.string().required("Required"),
      });
    
       return (
    <Base title="Welcome back" description="Log In to Your Account">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-4 offset-sm-4 text-left">
                  <div className="form-group">
                    <label className="text-black">Email</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="email"
                      name="email"
                    />
                    <ErrorMessage name="email">
                      {(errorMsg) => <div className="error">{errorMsg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-group">
                    <label className="text-black">Password</label>
                    <Field
                      className="form-control"
                      id="password"
                      name="password"
                      type="password"
                    />
                    <ErrorMessage name="password">
                      {(errorMsg) => <div className="error">{errorMsg}</div>}
                    </ErrorMessage>
                  </div>
                  <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >
                   
                    <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>
                    LOGIN
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Base>
  );
      
};
export default Signin;
