import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OurPride=({history})=> {
    const [student_name, setStudentName] = useState("");
    const [year, setYear] = useState("");
    const [achievement, setAchievement] = useState("");
    const [allprides, setAllPrides] = useState([]);
    const [educationYears, setAllEducationalYears] = useState([]);
    const preload = () => {
        axios({
            method: 'get',
            url: `${API}/getAllPrides`,
           
          }).then(res=>{
              console.log(res)
              if(res.status===200)
              {
                  if(res.data.status!==true)
                  {
                      return alert('something went wrong')
                   
                  }
                  setAllPrides(res.data.pride);
               
              }
              else
              {
                console.log("error")
              }
          });
      };
      const getAllYears = () => {
        axios
        .post(
            `${API}/getAllYears`,
          JSON.stringify({
          
            year_type:"Educational_Year"
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.year)
                 
                  setAllEducationalYears(response.data.year);
                  
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
      };

      useEffect(() => {
        preload();
        getAllYears();
      }, []);
    const ourprideScreen=()=>{
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            
          student_name: student_name,
          year:year,
          achievement:achievement,
            
          };
          const validationSchema = Yup.object({
                  
            student_name: Yup.string().required("Required"),
            year: Yup.string().required("Required"),
            achievement: Yup.string().required("Required"),
          
           
           
          });
        const onSubmit = (values, submitProps) => {
           console.log(values)
           submitProps.setSubmitting(true);
           axios
            .post(
                `${API}/addpridename`,
              JSON.stringify({
                values
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log(response);
              if(response.status===200)
                {
                    if(response.data.status==true)
                    {    
                      submitProps.setSubmitting(false);
                      toast('Saved successfully')  
                     
                       preload()
                    }
                    else
                    {
                        submitProps.setSubmitting(false);
                        
                       // container.success(response.data.message)
                       alert("response.data.message")
                        //setError(response.data.message)
                    }                 
                }
                else
                {
                    alert("error")
                  submitProps.setSubmitting(false);
                }
            })
            .catch((error) => {
              console.log(error.message);
            });
        
          };
        return(
            <div className="container" style={{marginTop:"50px"}}>
            
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Our Pride
</div>
                <div className="row">
                <div className="col-md-6">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
            
               
                  <div className="form-group">
                    <label className="text-black">Student Name</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="student_name"
                      name="student_name"
                      
                    />
                      <ErrorMessage name="student_name">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>
                 
                
                  <div className="form-group">
                    <label className="text-black">Achievement</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"                      
                      id="achievement"
                      name="achievement"                      
                    />
                      <ErrorMessage name="achievement">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                   
                  </div>
                                        
             

            
          
                  <div className="form-group">
                    <label className="text-black">Year</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="year"
                      name="year"
                      
                    />
                  



                    {/* <Field
                      className="form-control"
                     
                      id="year"
                      name="year"
                      
                    /> */}
                      <ErrorMessage name="year">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>
                
               
                 
          
              
             
            
             
              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>
            </Form>
          );
        }}
      </Formik>
      </div>
      <div className="col-md-6">
      <MaterialTable  
        icons={tableIcons}  
          columns={[
           
            { title: "ID", field: "_id",editable:'never',disableClick:'true',hidden: true },
            { title: "Student Name", field: "student_name" },
            { title: "Achievement", field: "achievement" },
            { title: "Year", field: "year" },
            
          ]}
          data={allprides}     
          title="Our Prides"
          editable={{
            onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
              
                const dataDelete = [...allprides];
                const index = oldData.tableData.id;
                const Id = oldData._id; //actual DB id
              
                axios({
                  method: 'DELETE',
                  url:  `${API}/deletepride`,
                  data: {
                    id: Id,
                  
                  }
                 
                 }).then(
                  res=>{
                      console.log(res)
                      if(res.status===200)
                      {
                          if(res.data.status==true)
                          {
                            toast('Deleted Successfully')
                              dataDelete.splice(index, 1);
                              setAllPrides([...dataDelete]);
                    
                    resolve()
                           
                          }
                      
                      }
                      else
                      {
                        reject("Wrong")
                        console.log("error")
                      }
                  }
                )
              
              }, 1000)
            }),
          }}
        
        
          
        />
      </div>
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {ourprideScreen()}
        </BaseAdmin>
    )

}

export default OurPride