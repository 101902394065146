import React from 'react'
import Menu from './Menu'

// import { isAuthenticated } from '../auth/helper';
// import UserMenu from './UserMenu';
// import AdminSidebar from '../user/AdminSidebar';


 const Base= ({
     
     title="",
     description="",
  
    className="text-black p-4",
     children
 }) =>  
     (
         
        <div>
           
           <div className="fluid-container">
           { <Menu/>}
            
              
              
               <div className="text-black text-center">
                   <h2>{title}</h2>
                     <p className="lead">{description}</p>
               </div>
     <div className={className}>{children}</div>
               </div> 
               <footer className="footer">
                  
                   <div className="container">
                       <span className="text-muted">
                           <span className="text-white">SPS Bed Sangli</span>
                       </span>
                   </div>
               </footer>
        </div>
    )
     
    Base.oncontextmenu = function (e) {
        e.preventDefault();
    };

  
export default Base