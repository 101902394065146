import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import JsonData from './data.json'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { ProgressBar } from "react-bootstrap"
const AcaCourses=({ history })=> {
  const divStyle = {
    overflowY: 'scroll',
    border:'1px solid silver',
    width:'400px',
    float: 'left',
    height:'300px',
    position:'absulute'
  };
    const [course_name, setcourse_name] = useState("");
    const [university, setuniversity] = useState("");
    const [entry_qualification,setentry_qualification]=useState('')
    const [duration,setduration]=useState('')
    const [medium,setmedium]=useState('')
    const [intake_capacity,setintake_capacity]=useState('')
    const [content,setContent]=useState([])
    const [progress, setProgress] = useState()
      const getCourses = () => {    
    
        axios
        .get(
            `${API}/getcourses`,        
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.courses)
                //   var iqacDetails= response.data.iqac
                  setContent(response.data.courses);
                //   setSectionName(response.data.iqac[0].section_name)
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };
        const deleteCourses=(_id,file_url_name)=>{
        
            var result = window.confirm("Are you sure to delete this document?")
            if(result)
            {
             var file_url_name=file_url_name
             var id=_id
             axios({
               method: 'DELETE',
               url:  `${API}/deletecourse`,
               data: {
                id: id,            
                 file_url_name:file_url_name            
               }           
              }).then(
               res=>{
                   console.log(res)
                   if(res.status===200)
                   {
                       if(res.data.status==true)
                       {
                         toast('Document Deleted Successfully')
                         getCourses()                     
                        
                       }
                   
                   }
                   else
                   {
                    
                     console.log("error")
                   }
               }
             )
             }
   
           }
  
       
      useEffect(() => {
        getCourses();
       
      }, []);
    const CoursesScreen=()=>{
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            course_name:course_name,
            university:university,
            entry_qualification:entry_qualification,
            duration:duration,
            medium:medium,
            intake_capacity:intake_capacity,
            filesdata:'',
          };
          const validationSchema = Yup.object({
                  
            course_name: Yup.string().required("Required"),
            duration: Yup.string().required("Required"),
           
          });
        const onSubmit = (values, submitProps) => {
           console.log(values)
           submitProps.setSubmitting(true); 
           var formData = new FormData();
           var filesdata = values.filesdata           
        //    var course_name=values.course_name
        //    var university=values.university
        //    var entry_qualification=values.entry_qualification
        //    var duration=values.duration
        //    var medium=values.medium
        //    var intake_capacity=values.intake_capacity
           
           formData.append("filesdata", filesdata);  
         
           formData.append("values",JSON.stringify(values))
         
           axios({
            method: "post",
            url: `${API}/addcourse`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: data => {
              //Set the progress value to show the progress bar
              setProgress(Math.round((100 * data.loaded) / data.total))
            },
          })                  
          .then((response) => {
              
            
            if(response.status===200)
              {
                submitProps.setSubmitting(false); 
                  if(response.data.status==true)
                  {      
                    toast('Saved Successfully')   
                    setTimeout(() => {
                      setProgress()
                    }, 5000);
                    getCourses()
                  }
                  else
                  {
                      submitProps.setSubmitting(false);
                   
                     alert(response.data.message)
                     
                  }                 
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
          
            console.log(error.message);
          });
        
          };
        return(
            <div className="container-fluid" style={{marginTop:"50px"}}>
              <button type="button" onClick={() => history.push('/admin/academics')} class="btn btn-warning">Go to Academics</button>
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
Upload Course Details
</div>
                <div className="row">
                <div className="col-md-4">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
            
               
                  <div className="form-group">
                    <label className="text-black">Course Name</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="course_name"
                      name="course_name"
                      
                    />
                      <ErrorMessage name="course_name">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>    
                  <div className="form-group">
                    <label className="text-black">University Name</label>
                    <Field
                      className="form-control"
                      
                      id="university"
                      name="university"
                      
                    />                    
                  </div>       
                  <div className="form-group">
                    <label className="text-black">Entry Qualification</label>
                    <Field
                      className="form-control"
                      
                      id="entry_qualification"
                      name="entry_qualification"
                      
                    />                    
                  </div>       
                  <div className="form-group">
                    <label className="text-black">Duration</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      
                      id="duration"
                      name="duration"
                      
                    />  
                     <ErrorMessage name="duration">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>                  
                  </div>       
                  <div className="form-group">
                    <label className="text-black">Medium</label>
                    <Field
                      className="form-control"
                      
                      id="medium"
                      name="medium"
                      
                    />                    
                  </div>     
                  <div className="form-group">
                    <label className="text-black">Intake Capacity</label>
                    <Field
                      className="form-control"
                      
                      id="intake_capacity"
                      name="intake_capacity"
                      
                    />                    
                  </div>        
                  
                   <div className="form-group">
                    <label className="text-black">Upload File</label>
                    <input
                    className="form-control"
              type="file"
              name="filesdata"
              accept="application/pdf" 

              onChange={(event) =>{
                formik.setFieldValue("filesdata", event.target.files[0]);
              }}
            />
              {/* <ErrorMessage name="filesdata">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage> */}
                   {progress && <ProgressBar now={progress} label={`${progress}%`} />}
                  </div>   
                          
             
              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>
            </Form>
          );
        }}
      </Formik>
      </div>
      <div className="col-md-8">
      <div>
      <table class="table table-bordered">
              <thead>
              <th>-</th>
              <th>Related Document</th>
                  <th>Course Name</th>
                  <th>University </th>
                  <th>Entry Qualification </th>
                  <th>Duration </th>
                  <th>Medium </th>
                  <th>Intake Capacity</th>
              </thead>
                <tbody>
            
        {
            content.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(count);
                if(count>0)
                {
                   
                    if(info.file_url_name!='' && info.file_url_name!=undefined)
                    {
                return(
              
                      <tr>
                        <td><a href="#" onClick={() =>deleteCourses(info._id,info.file_url_name)}>Delete</a></td>
                          <td style={{color:'red'}}> <a href={'http://localhost:3000/filesData/'+info.file_url_name} target="_blank">  <i class="fa fa-file" aria-hidden="true"></i> </a> </td>
                          <td>{info.course_name}</td>
                          <td>{info.university}</td>
                          <td>{info.entry_qualification}</td>
                          <td>{info.duration}</td>
                          <td>{info.medium}</td>
                          <td>{info.intake_capacity}</td>
                          
                      </tr>
                     
                  )
                }
                else
                {
                    return(
              
                        <tr>
                          <td><a href="#" onClick={() =>deleteCourses(info._id,info.file_url_name)}>Delete</a></td>
                            <td style={{color:'red'}}> - </td>
                            <td>{info.course_name}</td>
                            <td>{info.university}</td>
                            <td>{info.entry_qualification}</td>
                            <td>{info.duration}</td>
                            <td>{info.medium}</td>
                            <td>{info.intake_capacity}</td>
                            
                        </tr>
                       
                    ) 
                }
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
      </div>
      </div>
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {CoursesScreen()}
        </BaseAdmin>
    )

}

export default AcaCourses