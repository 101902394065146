import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'

const FeedbackOptions=()=> {
  
 
    const feedbackOptionsScreen=()=>{
     
        return(
            <div className="container" style={{marginTop:"50px"}}>
             
                <div className="row">
              
      <div className="col-md-12">
      <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Options Under Feedback </th>
   
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>  <Link className="nav-link" to="/admin/feedback/form"> Feedback Form Google Link</Link></td>
     
    </tr>
    
    <tr>
      <th scope="row">2</th>
      <td><Link className="nav-link" to="/admin/feedback/analysis">Feedback Analysis</Link></td>
     
    </tr>
   
  </tbody>
</table>
      </div>
    
    
      </div>

            </div>
            )
    }

    return(
        <BaseAdmin>
        {feedbackOptionsScreen()}
        </BaseAdmin>
    )

}

export default FeedbackOptions