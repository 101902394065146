import React from 'react'
import{BrowserRouter,Switch,Route, Router, HashRouter} from "react-router-dom"
import Dashboard from './main/Dashboard.js'
import Signin from './main/Signin.js'

import ProtectedRoute from './core/routes/ProtectedRoute.js'
import IQAC from './main/IQAC.js'

import OurPride from './main/OurPride.js'
import Signup from './main/Signup.js'
import IQACContent from './main/IQACContent.js'
import Academics from './main/Academics.js'
import AcaMeritList from './main/AcaMeritList.js'
import AcaAcademicCalendar from './main/AcaAcademicCalendar.js'
import AcaFeeStructure from './main/AcaFeeStructure.js'
import AcaStudentDetails from './main/AcaStudentDetails.js'
import OtherOptions from './main/OtherOptions.js'
import OtherFacilities from './main/OtherFacilities.js'
import OtherCommittees from './main/OtherCommittees.js'
import OtherRTI from './main/OtherRTI.js'
import OtherNCTE from './main/OtherNCTE.js'
import OtherUGC from './main/OtherUGC.js'
import NewsAndAnnoncements from './main/NewsAndAnnoncements.js'
import GalleryYear from './main/GalleryYear.js'
import GalleryEventName from './main/GalleryEventName.js'
import GalleryFiles from './main/GalleryFiles.js'
import OtherCollegeDocuments from './main/OtherCollegeDocuments.js'
import AcaCourses from './main/AcaCourses.js'
import StaffDetails from './main/StaffDetails.js'
import StaffDetailsUpdate from './main/StaffDetailsUpdate.js'
import NAAC from './main/NAAC.js'
import NAACContent from './main/NAACContent.js'
import FeedbackOptions from './main/FeedbackOptions.js'
import FeedbackForms from './main/FeedbackForms.js'
import FeedbackAnalisys from './main/FeedbackAnalisys.js'
//import VideoPlaylistPriview from './main/VideoPlaylistPriview.js'
const Routes=()=> {
    return (
     
      // <HashRouter>
      <BrowserRouter>
      <Switch>
          {/* <ProtectedRoute path="/" exact component={Signin}/> */}
          <Route path="/" exact component={Signin}/>
          <Route path="/signup" exact component={Signup}/>
          <Route path="/admin/dashboard" exact component={Dashboard}/>
          <Route path="/admin/IQAC" exact component={IQAC}/>
          <Route path="/admin/IQACContent" exact component={IQACContent}/>
          <Route path="/admin/NAAC" exact component={NAAC}/>
          <Route path="/admin/NAACContent" exact component={NAACContent}/>
          
          <Route path="/admin/staffdetails" exact component={StaffDetails}/>
          <Route path="/admin/updatestaffdetails" exact component={StaffDetailsUpdate}/>
          
          <Route path="/admin/ourpride" exact component={OurPride}/>
          <Route path="/admin/academics" exact component={Academics}/>
          <Route path="/admin/meritlist" exact component={AcaMeritList}/>
          <Route path="/admin/academiccalendar" exact component={AcaAcademicCalendar}/>
          <Route path="/admin/feestructure" exact component={AcaFeeStructure}/>
          <Route path="/admin/studentdetails" exact component={AcaStudentDetails}/>
          <Route path="/admin/courses" exact component={AcaCourses}/>

          <Route path="/admin/otheroptions" exact component={OtherOptions}/>
          <Route path="/admin/others/facilities" exact component={OtherFacilities}/>
          <Route path="/admin/others/committees" exact component={OtherCommittees}/>
          <Route path="/admin/others/rti" exact component={OtherRTI}/>
          <Route path="/admin/others/ncte" exact component={OtherNCTE}/>
          <Route path="/admin/others/ugc" exact component={OtherUGC}/>
          <Route path="/admin/others/collegedocuments" exact component={OtherCollegeDocuments}/>
          <Route path="/admin/newsandannoncements" exact component={NewsAndAnnoncements}/>
          <Route path="/admin/galleryyear" exact component={GalleryYear}/>
          <Route path="/admin/galleryeventname" exact component={GalleryEventName}/>
          <Route path="/admin/galleryphotos" exact component={GalleryFiles}/>
          <Route path="/admin/feedbackoptions" exact component={FeedbackOptions}/>
          <Route path="/admin/feedback/form" exact component={FeedbackForms}/>
          <Route path="/admin/feedback/analysis" exact component={FeedbackAnalisys}/>

          
          
          
      </Switch>
        </BrowserRouter>
       // </HashRouter> 
    
     
    )
}
export default Routes;