import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'

const OtherOptions=()=> {
  
 
    const otheroptionsScreen=()=>{
     
        return(
            <div className="container" style={{marginTop:"50px"}}>
             
                <div className="row">
              
      <div className="col-md-12">
      <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Options Under Other </th>
   
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>  <Link className="nav-link" to="/admin/others/facilities"> Facilities</Link></td>
     
    </tr>
    {/* <tr>
      <th scope="row">2</th>
      <td><Link className="nav-link" to="/admin/others/committees">Committees</Link></td>
    
    </tr> */}
    <tr>
      <th scope="row">2</th>
      <td><Link className="nav-link" to="/admin/others/rti">RTI</Link></td>
     
    </tr>
    {/* <tr>
      <th scope="row">3</th>
      <td><Link className="nav-link" to="/admin/others/ncte">NCTE</Link></td>
     
    </tr> */}
    <tr>
      <th scope="row">3</th>
      <td><Link className="nav-link" to="/admin/others/ugc">UGC/RUSA</Link></td>
     
    </tr>
    <tr>
      <th scope="row">4</th>
      <td><Link className="nav-link" to="/admin/others/collegedocuments">College Documents</Link></td>
     
    </tr>
  </tbody>
</table>
      </div>
    
    
      </div>

            </div>
            )
    }

    return(
        <BaseAdmin>
        {otheroptionsScreen()}
        </BaseAdmin>
    )

}

export default OtherOptions