import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { API } from '../backend'
import { APIMEDIA } from '../backend'
const Dashboard=()=> {
  
 
    const dashboardScreen=()=>{
     
        return(
            <div className="container" style={{marginTop:"50px"}}>
             
                <div className="row">
              
      <div className="col-md-4">
      <Link className="nav-link" to="/admin/IQAC"> 
      <div class="card" style={{backgroundColor:'yellow'}}>
    <div class="card-body">
        <h5 class="card-title">IQAC</h5>
        <h6 class="card-subtitle mb-3 text-muted">Upload all IQAC related documents here</h6>
      
    </div>   
</div>
</Link>
      </div>
      <div className="col-md-4">
      <Link className="nav-link" to="/admin/ourpride"> 
      <div class="card" style={{backgroundColor:'yellow'}}>
    <div class="card-body">
        <h5 class="card-title">Our Prides</h5>
        <h6 class="card-subtitle mb-3 text-muted">Add student names and achievements for our pride section</h6>
      
    </div>
</div>
</Link>
      </div>
      <div className="col-md-4">
      <Link className="nav-link" to="/admin/academics"> 
      <div class="card" style={{backgroundColor:'yellow'}}>
    <div class="card-body">
        <h5 class="card-title">Academics</h5>
        <h6 class="card-subtitle mb-3 text-muted">Upload academics related documents</h6>
      
    </div>
</div>
</Link>
      </div>
      </div>

      <div className="row" style={{marginTop:"20px"}}>
              
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/otheroptions"> 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">Other</h5>
                <h6 class="card-subtitle mb-3 text-muted">Upload documents in other options</h6>
              
            </div>
        </div>
        </Link>
              </div>
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/galleryyear" > 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">Gallery</h5>
                <h6 class="card-subtitle mb-3 text-muted">Upload gallery photos</h6>
              
            </div>
        </div>
        </Link>
              </div>
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/staffdetails" > 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">Staff Details</h5>
                <h6 class="card-subtitle mb-3 text-muted">Upload staff details</h6>
              
            </div>
        </div>
        </Link>
              </div>
              </div>


              <div className="row" style={{marginTop:"20px"}}>
              
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/newsandannoncements"> 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">News And Annoncements</h5>
                <h6 class="card-subtitle mb-3 text-muted">Upload News And Annoncement</h6>
              
            </div>
        </div>
        </Link>
              </div>
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/others/ncte"> 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">NCTE</h5>
                <h6 class="card-subtitle mb-3 text-muted">Upload NCTE details</h6>
              
            </div>
        </div>
        </Link>
              </div>
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/NAAC"> 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">NAAC</h5>
                <h6 class="card-subtitle mb-3 text-muted">Upload NAAC Files</h6>
              
            </div>
        </div>
        </Link>
              </div>
              <div className="col-md-4">
              <Link className="nav-link" to="/admin/feedbackoptions"> 
              <div class="card" style={{backgroundColor:'yellow'}}>
            <div class="card-body">
                <h5 class="card-title">FEEDBACK</h5>
                <h6 class="card-subtitle mb-3 text-muted">Add Feedback Form</h6>
              
            </div>
        </div>
        </Link>
              </div>

              
              </div>
             
            </div>
            )
    }

    return(
        <BaseAdmin>
        {dashboardScreen()}
        </BaseAdmin>
    )

}

export default Dashboard