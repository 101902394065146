import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'

const Academics=()=> {
  
 
    const academicsScreen=()=>{
     
        return(
            <div className="container" style={{marginTop:"50px"}}>
             
                <div className="row">
              
      <div className="col-md-12">
      <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Options Under Academics</th>
   
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>  <Link className="nav-link" to="/admin/meritlist"> Admission/Merit List</Link></td>
     
    </tr>
    <tr>
      <th scope="row">2</th>
      <td><Link className="nav-link" to="/admin/academiccalendar">Academic Calendar</Link></td>
    
    </tr>
    <tr>
      <th scope="row">3</th>
      <td><Link className="nav-link" to="/admin/feestructure">Fee Structure</Link></td>
     
    </tr>
    <tr>
      <th scope="row">4</th>
      <td><Link className="nav-link" to="/admin/studentdetails">Student Details</Link></td>
     
    </tr>
    <tr>
      <th scope="row">5</th>
      <td><Link className="nav-link" to="/admin/others/committees">Committees</Link></td>
     
    </tr>
    <tr>
      <th scope="row">6</th>
      <td><Link className="nav-link" to="/admin/courses">Courses</Link></td>
     
    </tr>
  </tbody>
</table>
      </div>
    
    
      </div>

            </div>
            )
    }

    return(
        <BaseAdmin>
        {academicsScreen()}
        </BaseAdmin>
    )

}

export default Academics