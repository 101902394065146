import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { API } from '../backend'
import { APIMEDIA } from '../backend'

import { ProgressBar } from "react-bootstrap"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const NAACContent=({ history })=> {
  const divStyle = {
    overflowY: 'scroll',
    border:'1px solid silver',
    width:'400px',
    float: 'left',
    height:'300px',
    position:'absulute'
  };
    const [file_caption, setfile_caption] = useState("");
    const [filesdata, setfilefilesdata] = useState("");
    const [year,setyear]=useState([])
    const [content,setContent]=useState([])
    const [section_name,setSectionName]=useState("")
    const [educationYears, setAllEducationalYears] = useState([]);
    const [progress, setProgress] = useState()
    const [multiFiles,setMultiFiles]=useState([])
      const getIQACContenent = () => {
      
        let rowData = localStorage.getItem('selectedRowData')
        var iqacDetails= JSON.parse(rowData)
        var section_id=iqacDetails.section_id
        axios
        .post(
            `${API}/getnaacContent`,
          JSON.stringify({
          
            section_id:section_id
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.naac)
                  var iqacDetails= response.data.naac
                  setContent(response.data.naac[0].files);
                  setSectionName(response.data.naac[0].section_name)
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };
        const getAllYears = () => {
          axios
          .post(
              `${API}/getAllYears`,
            JSON.stringify({
            
              year_type:"Educational_Year"
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if(response.status===200)
              {
                  if(response.data.status==true)
                  {      
                   
                    console.log(response.data.year)
                   
                    setAllEducationalYears(response.data.year);
                    
                  }
                    
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
            console.log(error.message);
          });
        };
const viewFiles=(data)=>{
console.log("Files data",data.multi_files)
if(data.multi_files.length>0)
{
  setMultiFiles(data.multi_files)
}
else
{
  setMultiFiles([])

}
}
        const deleteFile=(file_id,file_url_name)=>{
          setMultiFiles([])
         var result = window.confirm("Are you sure to delete this document?")
         if(result)
         {
          let rowData = localStorage.getItem('selectedRowData')
          var iqacDetails= JSON.parse(rowData)
          var section_id=iqacDetails.section_id
          axios({
            method: 'DELETE',
            url:  `${API}/deletenaacfile`,
            data: {
              section_id: section_id,
              file_id:file_id,
              file_url_name:file_url_name            
            }           
           }).then(
            res=>{
                console.log(res)
                if(res.status===200)
                {
                    if(res.data.status==true)
                    {
                      toast('Document Deleted Successfully')
                      getIQACContenent()                     
                     
                    }
                
                }
                else
                {
                 
                  console.log("error")
                }
            }
          )
          }

        }

      
      useEffect(() => {
        getIQACContenent();
        getAllYears();
      }, []);
    const NAACcontentScreen=()=>{
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            
          file_caption: file_caption,
          year:year,
          filesdata:'',
          web_link:''
          
          };
          const validationSchema = Yup.object({
                  
            file_caption: Yup.string().required("Required"),
           // year: Yup.string().required("Required"),
           // filesdata: Yup.string().required("Required"),
            
          });
        const onSubmit = (values, submitProps) => {
          submitProps.setSubmitting(true);
           console.log(values)
           if(values.web_link=='' && values.filesdata=='')
           {
             return alert("Please upload either a file or a web link")
           }
           var formData = new FormData();
           var filesdata = values.filesdata
       console.log("filesdata",filesdata)
           let rowData = localStorage.getItem('selectedRowData')
           var iqacDetails= JSON.parse(rowData)
           var section_id=iqacDetails.section_id

           values.filesdata.forEach(file=>{
            formData.append("filesdata", file);
          });
         //  formData.append("filesdata", filesdata);  
                       
           formData.append("values",JSON.stringify(values))
         
            formData.append("section_id",JSON.stringify(section_id))
           axios({
            method: "post",
            url: `${API}/addnaaccontent`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: data => {
              //Set the progress value to show the progress bar
              setProgress(Math.round((100 * data.loaded) / data.total))
            },
          })                  
          .then((response) => {
              
            
            if(response.status===200)
              {
                  if(response.data.status=="Success")
                  {     
                    submitProps.setSubmitting(false); 
                    toast('Document uploaded successfully')
                    setTimeout(() => {
                      setProgress()
                    }, 5000);
                     getIQACContenent()
                     setMultiFiles([])
                  }
                  else
                  {
                      submitProps.setSubmitting(false);
                   
                     alert(response.data.message)
                     
                  }                 
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
          
            console.log(error.message);
          });
        
          };
        return(
            <div className="container-fluid" style={{marginTop:"50px"}}>
              <button type="button" onClick={() => history.push('/admin/NAAC')} class="btn btn-warning">Go to NAAC</button>
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Upload files for main menu {section_name}
</div>
                <div className="row">
                <div className="col-md-6">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
            
               
                  <div className="form-group">
                    <label className="text-black">File Caption</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="file_caption"
                      name="file_caption"
                      
                    />
                      <ErrorMessage name="file_caption">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>            
                  <div className="form-group">
                    <label className="text-black">Year</label>
                    {/* <Field
                      className="form-control"
                      
                      id="year"
                      name="year"
                      
                    /> */}
                      <Field name="year"  id="year">
                                    {({ field }) => (
                                        <select {...field} className="form-control" >
                                            <option value="N/A">N/A</option>
                                            {educationYears.map(i => 
                                                <option key={i.id} value={i.year}>{i.year}</option>
                                            )}
                                        </select>
                                    )}
                                    </Field>

                      {/* <ErrorMessage name="year">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage> */}
                  
                  </div>   
                 

                   <div className="form-group">
                    <label className="text-black">Upload File</label><label style={{color:"red"}}>*</label>
                    <input
                    className="form-control"
              type="file"
              name="filesdata"
              // accept="application/pdf" 
              multiple
              onChange={(event) =>{
                const files = event.target.files;
                let myFiles =Array.from(files);
                formik.setFieldValue("filesdata", myFiles);
               // formik.setFieldValue("filesdata", event.target.files[0]);
              }}
            />
              <ErrorMessage name="filesdata">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                    {progress && <ProgressBar now={progress} label={`${progress}%`} />}
                  </div>   

                  <div className="form-group">
                    <label className="text-black">Web Link</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                     
                      id="web_link"
                      name="web_link"
                      
                    />
                    
                  
                  </div>  
                          
             
              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>
            </Form>
          );
        }}
      </Formik>
      </div>
      <div className="col-md-6">
      <div style={divStyle}>
      <table class="table table-container">
              
                <tbody>
            
        {
            content.map(
              (info) =>
              {
                var count = Object.keys(info).length;
                console.log(info);
                if(count>0)
                {
                  if(info.isWebLink=="true")
                  {
                return(
              
                      <tr>
                         <td><a href="#" onClick={() =>deleteFile(info._id,info.file_url_name)}>Delete</a></td>

                          <td style={{color:'red'}}> <a href={APIMEDIA+"/filesdata/"+info.file_url_name} target="_blank">  {info.file_caption} </a> </td>
                          <td>{info.year}</td>
                          
                      </tr>
                     
                  )
                }
                else
                {
                  
                    return(
              
                      <tr>
                         <td><a href="#" onClick={() =>deleteFile(info._id,info.file_url_name)}>Delete</a></td>
                        

                         <td style={{color:'red'}}>
                          {info.is_multi_files?
                          <a href="#" onClick={() =>viewFiles(info)}>
                             {info.file_caption} 
                             </a>
                            :
                             <a href={APIMEDIA+"/filesdata/"+info.file_url_name} target="_blank"> 
                             {info.file_caption} 
                             </a>
                            }
                             </td>
                          <td>{info.year}</td>
                          
                      </tr>
                     
                  )
                  
                }
                }
              
              }
            )
        }
                    
              {/* {preload} */}
                    
                </tbody>
            </table>
      </div>
      </div>
      </div>
      <div className='row'>
        {
          multiFiles.map((file,index)=>{
            console.log("File check",file)
            return(
              <div className='col-md-3'>
              <a href={APIMEDIA+"/filesdata/"+file.file_url_name} target="_blank">
              {file.originalname}
                </a> 
              </div>

            )
          })
        }
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {NAACcontentScreen()}
        </BaseAdmin>
    )

}

export default NAACContent