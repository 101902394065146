import React from 'react'
import {withRouter} from "react-router-dom";
// import "../App.css"


const Menu = ({ history }) => 
    (
        <div>
           <nav className="navbar navbar-expand-lg navbar-light bg-light">
           <a className="navbar-brand" href="#">S.P.S. College of Education Sangli</a>
       
            <div className="nav ml-auto">
          
            <button onClick={() => history.push('/')} type="button" className="btn btn-default btn-sm">LOGIN</button>           
         
            <button onClick={() => history.push('/signup')} type="button" className="btn info-color btn-sm">SIGN UP</button>
          
          
        </div>
      
    </nav>
          
        </div>
    )

export default withRouter(Menu);