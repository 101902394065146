import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FeedbackForms=({history})=> {
    const divStyle = {
        overflowY: 'scroll',
        border:'1px solid silver',
        width:'400px',
        float: 'left',
        height:'300px',
        position:'absulute'
      };
    const [feedback_link, setFeedbackLink] = useState("");
    const [year, setYear] = useState("");
    const [feedback_caption, setFeedbackCaption] = useState("");
    const [allforms, setAllFeedbackForms] = useState([]);
    const [educationYears, setAllEducationalYears] = useState([]);
    const preload = () => {
        axios({
            method: 'get',
            url: `${API}/getFeedbackForm`,
           
          }).then(res=>{
              console.log(res)
              if(res.status===200)
              {
                  if(res.data.status!==true)
                  {
                      return alert('something went wrong')
                   
                  }
                  setAllFeedbackForms(res.data.feedbackform);
               
              }
              else
              {
                console.log("error")
              }
          });
      };
      const getAllYears = () => {
        axios
        .post(
            `${API}/getAllYears`,
          JSON.stringify({
          
            year_type:"Educational_Year"
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                  console.log(response.data.year)
                 
                  setAllEducationalYears(response.data.year);
                  
                }
                  
            }
            else
            {
                alert("error")
              
            }
        })
        .catch((error) => {
          console.log(error.message);
        });
      };

      useEffect(() => {
        preload();
        getAllYears();
      }, []);
    const feedbackformScreen=()=>{
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            
            feedback_link: feedback_link,
            year:year,
            feedback_caption:feedback_caption,
            
          };
          const validationSchema = Yup.object({
                  
            feedback_link: Yup.string().required("Required"),
            year: Yup.string().required("Required"),
            feedback_caption: Yup.string().required("Required"),
          
           
           
          });
        const onSubmit = (values, submitProps) => {
           console.log(values)
           submitProps.setSubmitting(true);
           axios
            .post(
                `${API}/addFeedbackForm`,
              JSON.stringify({
                values
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log("Check response",response);
              if(response.status===200)
                {
                    if(response.data.error==false)
                    {    
                      submitProps.setSubmitting(false);
                      toast('Feedback form Saved successfully')  
                     
                       preload()
                    }
                    else
                    {
                        submitProps.setSubmitting(false);
                        
                       // container.success(response.data.message)
                       alert("response.data.message")
                        //setError(response.data.message)
                    }                 
                }
                else
                {
                    alert("error")
                  submitProps.setSubmitting(false);
                }
            })
            .catch((error) => {
              console.log(error.message);
            });
        
          };
          const deleteFeedbackForm=(_id)=>{
        
            var result = window.confirm("Are you sure to delete this document?")
            if(result)
            {
            
             var id=_id
             axios({
               method: 'DELETE',
               url:  `${API}/deleteFeedbackForm`,
               data: {
                id: id,            
               
               }           
              }).then(
               res=>{
                   console.log(res)
                   if(res.status===200)
                   {
                       if(res.data.status==true)
                       {
                         toast('Document Deleted Successfully')
                         preload()                     
                        
                       }
                   
                   }
                   else
                   {
                    
                     console.log("error")
                   }
               }
             )
             }
   
           }
        return(
            <div className="container" style={{marginTop:"50px"}}>
            
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Create Feedback Links
</div>
<button type="button" onClick={() => history.push('/admin/feedbackoptions')} class="btn btn-warning">Go Back</button>

                <div className="row">
                <div className="col-md-6">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
            
               
                  <div className="form-group">
                    <label className="text-black">Feedback Link</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="feedback_link"
                      name="feedback_link"
                      
                    />
                      <ErrorMessage name="feedback_link">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div>
                 
                
                  <div className="form-group">
                    <label className="text-black">Display Caption</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"                      
                      id="feedback_caption"
                      name="feedback_caption"                      
                    />
                      <ErrorMessage name="feedback_caption">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                   
                  </div>
                                        
                  <div className="form-group">
                    <label className="text-black">Year</label>
                    <Field name="year"  id="year">
                                    {({ field }) => (
                                        <select {...field} className="form-control" >
                                            <option value="N/A">N/A</option>
                                            {educationYears.map(i => 
                                                <option key={i.id} value={i.year}>{i.year}</option>
                                            )}
                                        </select>
                                    )}
                                    </Field>
                  
                  </div>   

            
          
                  {/* <div className="form-group">
                    <label className="text-black">Year</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="year"
                      name="year"
                      
                    />
                  
                      <ErrorMessage name="year">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div> */}
                
               
                 
          
              
             
            
             
              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>
            </Form>
          );
        }}
      </Formik>
      </div>
      <div className="col-md-6">
      <div style={divStyle}>
      <table class="table table-container">
              
              <tbody>
          
      {
          allforms.map(
            (info) =>
            {
              var count = Object.keys(info).length;
              console.log(count);
              if(count>0)
              {
              return(
            
                    <tr>
                      <td>
                        <a href="#" onClick={() =>deleteFeedbackForm(info._id)}>Delete</a>
                        </td>
                         <td style={{color:'red'}}> 
                         {/* <a href={APIMEDIA+"/filesdata/"+info.form_link} target="_blank">   */}
                         <a href={info.form_link} target="_blank">  

                         {info.form_caption} 
                         </a> 
                         </td>
                        <td>{info.form_year}</td>
                        
                    </tr>
                   
                )
              }
            
            }
          )
      }
                  
            {/* {preload} */}
                  
              </tbody>
          </table>
          </div>
      {/* <MaterialTable  
        icons={tableIcons}  
          columns={[
           
            { title: "ID", field: "_id",editable:'never',disableClick:'true',hidden: true },
            { title: "Form Link", field: "form_caption" },           
            { title: "Year", field: "form_year" },
            
          ]}
          data={allforms}     
          title="Our Prides"
          editable={{
            onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
              
                const dataDelete = [...allforms];
                const index = oldData.tableData.id;
                const Id = oldData._id; //actual DB id
              
                axios({
                  method: 'DELETE',
                  url:  `${API}/deleteFeedbackForm`,
                  data: {
                    id: Id,
                  
                  }
                 
                 }).then(
                  res=>{
                      console.log(res)
                      if(res.status===200)
                      {
                          if(res.data.status==true)
                          {
                            toast('Deleted Successfully')
                              dataDelete.splice(index, 1);
                              setAllFeedbackForms([...dataDelete]);
                    
                    resolve()
                           
                          }
                      
                      }
                      else
                      {
                        reject("Wrong")
                        console.log("error")
                      }
                  }
                )
              
              }, 1000)
            }),
          }}
        
        
          
        /> */}
      </div>
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {feedbackformScreen()}
        </BaseAdmin>
    )

}

export default FeedbackForms