import React,{useState,useEffect} from 'react'

import { forwardRef } from 'react';
import {Link,Redirect} from "react-router-dom"
import BaseAdmin from '../core/BaseAdmin'
import { Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { API } from '../backend'
import { APIMEDIA } from '../backend'
import JsonData from './data.json'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const GalleryEventName=({ history })=> {
  const divStyle = {
    overflowY: 'scroll',
    border:'1px solid silver',
    width:'400px',
    float: 'left',
    height:'300px',
    position:'absulute'
  };
  
    const [program_name,setEventName]=useState()
    const [for_year,setYear]=useState()
    
    const [educationYears, setAllEducationalYears] = useState([]);
    const [allEventsOfYear, setAllEventsOfYear] = useState([]);
    const [allYears, setAllYears] = useState([]);
      const getPhotoGalleryEvents = () => {
      
        let rowData = localStorage.getItem('selectedRowDataGalleryYear')
        var galleryDetails= JSON.parse(rowData)
        var gallery_id=galleryDetails.gallery_id
        setYear(galleryDetails.year)
        axios
        .post(
            `${API}/getPhotoGalleryProgramsByYear`,   
            JSON.stringify({
          
                gallery_id:gallery_id
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }       
        )
        .then((response) => {
          console.log(response);
          if(response.status===200)
            {
                if(response.data.status==true)
                {      
                 
                 // console.log(response.data.iqac)
                 console.log("Success")
               
                // setAllEventsOfYear(response.data.photogallery[0].program)
                setAllEventsOfYear(response.data.photogallery[0].program)
                setAllYears(response.data.photogallery)
                }
                  
            }
          //   else
          //   {
          //       alert("error")
              
          //   }
        })
        .catch((error) => {
          console.log(error.message);
        });
  
        };
       

      
      useEffect(() => {
        getPhotoGalleryEvents();     
      }, []);
    const GalleryEventNameScreen=()=>{
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const initialValues = {
            
         
            program_name:program_name,
        
          
          };
          const validationSchema = Yup.object({                            
            program_name: Yup.string().required("Required"),                      
          });
        const onSubmit = (values, submitProps) => {
          submitProps.setSubmitting(true); 
            let rowData = localStorage.getItem('selectedRowDataGalleryYear')
            var galleryDetails= JSON.parse(rowData)
            var gallery_id=galleryDetails.gallery_id
            
           axios
           .post(
               `${API}/addprogramname`,
             JSON.stringify({
               values,
               gallery_id:gallery_id
             }),
             {
               headers: {
                 "Content-Type": "application/json",
               },
             }
           )                
          .then((response) => {
              console.log(response)
            
            if(response.status===200)
              {
                submitProps.setSubmitting(false); 
                  if(response.data.status==true)
                  {      
                    toast('Saved Successfully') 
                     getPhotoGalleryEvents()
                  }
                  else
                  {
                      submitProps.setSubmitting(false);
                   
                     alert(response.data.message)
                     
                  }                 
              }
              else
              {
                  alert("error")
                
              }
          })
          .catch((error) => {
          
            console.log(error.message);
          });
        
          };
        return(
            <div className="container-fluid" style={{marginTop:"50px"}}>
               <button type="button" onClick={() => history.push('/admin/galleryyear')} class="btn btn-warning">Go Back</button>
               <div class="alert alert-danger" role="alert" style={{marginTop:"10px"}}>
 Add Event Name for year {for_year}
</div>
                <div className="row">
                <div className="col-md-6">
                     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
            
               
                
            <div className="form-group">
                    <label className="text-black">Event Name</label><label style={{color:"red"}}>*</label>
                    <Field
                      className="form-control"
                      autoFocus
                      id="program_name"
                      name="program_name"
                      
                    />
                      <ErrorMessage name="program_name">
                      {(errorMsg) => <div style={{color:"red"}}>{errorMsg}</div>}
                    </ErrorMessage>
                  
                  </div> 
                                                        
              <div className="row">
             
                      <div className="col-md-6">
                      <div className="form-group">
                      <button
                    type="submit"
                    disabled={ formik.isSubmitting}
                    className="btn btn-success"
                  >    
                   <i
                      className={`${
                        formik.isSubmitting ? "fa fa-spinner fa-spin" : "null"
                      }`}
                    ></i>                                  
                    Save
                  </button>
                  </div>
                      </div>
                  </div>
            </Form>
          );
        }}
      </Formik>
      </div>
      <div className="col-md-6">
      <div>
      <MaterialTable  
        icons={tableIcons}  
          columns={[
           
            { title: "ID", field: "_id",editable:'never',disableClick:'true',hidden: true },
            { title: "Program Name", field: "program_name" },
            { title: "Year", field: "year" },
           
            
          ]}
          data={allEventsOfYear}     
          title="Years"
          actions={[
            {
              icon:() => <Link to='/admin/galleryphotos'>Add</Link>,
              tooltip: 'Upload Files',
              onClick: (event, rowData) =>  localStorage.setItem('selectedRowDataGalleryWithEvents', JSON.stringify( rowData))
            }
          ]}  
          editable={{
            onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
              
                const dataDelete = [...allEventsOfYear];
                const index = oldData.tableData.id;
                const Id = oldData._id; 
               
                let rowData = localStorage.getItem('selectedRowDataGalleryYear')
                var galleryDetails= JSON.parse(rowData)
                var gallery_id=galleryDetails._id
                axios({
                  method: 'DELETE',
                  url:  `${API}/deleteGalleryEvent`,
                  data: {
                    programId: Id,
                    gallery_id:gallery_id
                  
                  }
                 
                 }).then(
                  res=>{
                      console.log(res)
                      if(res.status===200)
                      {
                          if(res.data.status==true)
                          {
                            toast('Deleted Successfully') 
                              dataDelete.splice(index, 1);
                              setAllEventsOfYear([...dataDelete]);
                    
                    resolve()
                           
                          }
                      
                      }
                      else
                      {
                        reject("Wrong")
                        console.log("error")
                      }
                  }
                )
              
              }, 1000)
            }),
          }}
        
        
          
        />
      </div>
      </div>
      </div>
            </div>
            )
    }

    return(
        <BaseAdmin>
        {GalleryEventNameScreen()}
        </BaseAdmin>
    )

}

export default GalleryEventName